.top-contacts {
  background-color: transparent;
  border-bottom: thin solid $light-blue-darker;
  font-size: 14px;
  min-height: 18px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  position: relative;
  width: 100%;
  z-index: 100000;
  button {
    display: none; }
  ul {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    li {
      color: $light-blue-darker; }
    a {
      color: $light-blue; }
    svg.contacts-icon, svg {
      fill: $light-blue-darker;
      margin-right: 8px;
      position: relative;
      top: 2px; } }
  @include breakpoint(mobile, max) {
    background-color: $dark-blue;
    display: none;
    justify-content: center;
    position: absolute;
    button {
      display: block;
      position: absolute;
      top: 20px;
      right: 16px; }
    ul {
      flex-direction: column; }
    li {
      padding: 12px 0; } } }

.top-contacts.active {
  display: block; }

#toggle-top-bar {
  margin-left: 4px;
  position: relative;
  @include breakpoint(mobile, min) {
    display: none; } }

%menu-link-active {
  border-bottom: 2px solid $light-blue-darker;
  color: $light-blue-darker;
  margin-bottom: -2px; }

.navigation {
  padding-right: 1rem;
  position: relative;
  z-index: 10;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(14,32,95,0.4) 0%, rgba(35,61,148,0) 100%);
    position: absolute;
    top: -40px;
    left: 0; } }

.title-bar {
  align-items: center;
  background-color: rgba(0,0,0,0); }


.top-bar, .top-bar ul {
  align-items: center;
  background-color: rgba(0,0,0,0);
  a {
    color: $light-blue; } }

.top-bar {
  @include breakpoint(mobile, max) {
    font-size: 16px; }
  @include breakpoint(402px, max) {
    font-size: 14px;
    button, a {
      padding: .55em .85em; }
    svg.logo {
      width: 40px;
      height: 40px;
      @include breakpoint(377px, max) {
        height: 24px;
        width: 24px; } } } }

.top-bar__buttons {
  display: flex;
  flex-direction: row-reverse; }

.menu__link {
  transition: background .5s;
  &:hover {
    @extend %menu-link-active; }
  &.active {
    @extend %menu-link-active; } }

.top-bar-left, .top-bar-right {
  flex: 0 auto!important; }

.lang-menu {
    background-color: $dark-blue !important;
    position: absolute;
    right: 2rem;
    a:hover {
      background-color: $blue; } }

.lang-menu-button {
  background: $blue;
  margin: 0;
  position: relative;
  z-index: 10000000000;
  &:hover {
    background: $dark-blue; } }

.lang-link.activelang {
  background-color: $blue; }
