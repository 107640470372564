.mt-4rem {
  margin-top: 4rem; }

.mt-3rem {
  margin-top: 3rem; }

.mb-4rem {
  margin-bottom: 4rem; }

.mb-2rem {
  margin-bottom: 2rem; }

.mr-2rem {
  margin-right: 2rem; }
