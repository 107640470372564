/* Breakpoints */
@mixin orientation($direction) {

  $orientation-landscape: "orientation:landscape";
  $orientation-portrait: "orientation:portrait";

  @if $direction == landscape {
    @media ($orientation-landscape) {
      @content; } }

  @if $direction == portrait {
    @media ($orientation-portrait) {
      @content; } } }

// A map of breakpoints.
$breakpoints: (mobile: 640px, laptop: 1024px, desktop: 1500px, desktop-lg: 1800px);

@mixin breakpoint($breakpoint, $direction) {
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @if $direction == max {
      @media (max-width: ($breakpoint-value - 1)) {
        @content; } }

    @else if $direction == min {
      @media (min-width: $breakpoint-value) {
        @content; } }

    @else {
      @media ($direction: $breakpoint-value) {
        @content; } } }

  // If the breakpoint doesn't exist in the map.
  @else {
    @if $direction == max {
      @media (max-width: $breakpoint) {
        @content; } }

    @else if $direction == min {
      @media (min-width: $breakpoint) {
        @content; } }

    @else {
      @media ($direction: $breakpoint) {
        @content; } } } }


@mixin text-shadow-s($color) {
  text-shadow: 2px 2px 4px $color; }

%label {
  color: $white; }

@mixin label-orange {
  @extend %label;
  @include text-shadow-s($orange-darker-20);
  background: $orange; }

@mixin label-blue {
  @extend %label;
  @include text-shadow-s($blue-darker-20);
  background: $blue; }

@mixin label-dark-blue {
  @extend %label;
  background: $dark-blue; }
