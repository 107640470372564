.contacts-container {
  align-items: top;
  display: flex;
  flex-direction: column;
  width: 100%;
  p, h2, h3, label, ul, a {
    color: $white; }
  @include breakpoint(laptop, min) {
    flex-direction: row;
    padding-left: 3rem;
    padding-right: 3rem; } }

.contacts-container__cta, .contacts-container__form {
  display: block;
  padding: 1rem;
  width: 100%;
  @include breakpoint(laptop, min) {
    width: 50%; }
  p, span, a {
    color: $light-blue; }
  span.message-warning {
    color: $orange; }
  li+li {
    margin-top: .5rem; } }

.contacts-image {
  @include breakpoint(laptop, min) {
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%); } }
