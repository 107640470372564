.footer {
  font-size: 0.9rem;
  padding-top: 0.5rem;
  & > div {
    min-height: 3rem; } }

.footer__row {
  display: flex;
  flex-direction: column-reverse;
  @include breakpoint(laptop, min) {
    flex-direction: row;
    justify-content: space-between; } }

.footer__container {
  padding-left: 2rem;
  @include breakpoint(laptop, min) {
    padding-left: 4rem; } }

.footer__menu {
  text-align: center;
  @include breakpoint(laptop, min) {
    text-align: right;
    padding-right: 4rem; }
  li {
    display: block;
    height: auto;
    overflow: auto;
    padding-right: 1rem;
    @include breakpoint(laptop, min) {
      display: inline; }
    a {
      display: block;
      height: 2.5rem;
      margin-bottom: .5rem;
      padding: .5rem 1rem;
      @include breakpoint(laptop, min) {
        float: right;
        height: 2rem; } } } }


.footer__author-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include breakpoint(laptop, min) {
    flex-direction: row; } }

.footer__author {
  margin-top: 2.5rem;
  padding-right: 2rem;
  @include breakpoint(laptop, min) {
    margin-top: 0; } }

.footer__design {
  color: $light-blue-darker!important;
  margin-top: 1.5rem;
  padding: 1rem 0;
  @include breakpoint(laptop, min) {
    margin-top: 0;
    margin-left: .5rem; } }
