$marketing-site-hero-height: 50vh;

.marketing-site-hero--rotated {
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0,77,179);
  background: linear-gradient(90deg, rgba(0,77,179,1) 0%, rgba(5,97,209,1) 24%, rgba(58,178,255,1) 63%, rgba(34,157,254,1) 77%, rgba(18,78,157,1) 100%);
  background-size: cover;
  transform: translateY(-15%) skewY(-4deg);
  @include breakpoint(laptop, max) {
    @include orientation(landscape) {
      height: 190vh; } } }


.marketing-site-hero {
  align-items: center;
  display: flex;
  height: auto;
  @include breakpoint(laptop, min) {
    height: $marketing-site-hero-height; } }

.marketing-site-hero-content {
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  z-index: 1;

  @include breakpoint(laptop, min) {
    align-items: center;
    flex-direction: row;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0; }

  h1 {
    color: $white;
    //font-size: 32px
    padding: 0;
    line-height: 1; }

  h2 {
    //color: $light-blue-darker
    color: $dark-blue;
    //font-size: 22px
    padding-bottom: 12px;
    line-height: 1; }

  p {
    color: #F4F8FF; }

  a {
    background-color: #F87500; }

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0; }

  .marketing-site-hero-content__img, .marketing-site-hero-content__text {

    width: 100%; }

  .marketing-site-hero-content__img {
    overflow: hidden;
    svg {
      width: 100%; }
    @include breakpoint(laptop, min) {
      width: 60%; } }

  .marketing-site-hero-content__text {
    @include breakpoint(laptop, min) {
      width: 40%; } } }
