.accortabs {
	box-shadow: $cardShadowXLarge;
	@include breakpoint(mobile, min) {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		.tabs.vertical {
			border: 0;
			flex-basis: 25%;
			background-color: $dark-blue; }

		.tabs-content {
			border: 0;
			flex-basis: 75%;
			padding: rem-calc(20); } } }

.accortabs-footer {
	margin-top: 0; }

.accordion-title, .tabs-title {
	a {
		font-family: inherit;
		font-size: 0.85rem;
		font-weight: 500;
		line-height: 1.5; } }

.tabs-title > a, .accordion-title {
	@include label-dark-blue;
	padding-right: 2rem;
	transition: all .5s; }

.accordion-title--main:focus, .accordion-title--main[aria-selected="true"] {
	@include label-orange; }

.tabs-title > a:focus, .tabs-title > a[aria-selected="true"] {
	@include label-orange;
	align-items: center;
	display: flex;
	height: 80px;
	position: relative;
	&::before {
		background-color: $white;
		content: '';
		display: block;
		height: 58px;
		right: -30px;
		position: absolute;
		transform: rotateZ(45deg);
		top: 11px;
		width: 58px; } }

.tabs-panel, .accordion-content {
	padding: 10%;
	@include breakpoint(mobile, min) {
		padding: 3%; }
	ul {
		list-style: none; }
	li {
		display: flex;
		&::before {
			display: block;
			content: '';
			height: 0;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-left: 6px solid $orange;
			margin-right: 10px;
			position: relative;
			top: 6px;
			width: 0;
			@include breakpoint(mobile, min) {
				top: 3px; } } } }

.accordion-content__wrapper {
	display: flex;
	flex-direction: column;
	@include breakpoint(mobile, min) {
		flex-direction: row;
		align-items: flex-start;
		img {
			padding-left: 15%; } } }

.accordion-content__text {
  padding-bottom: 3rem;
  @include breakpoint(mobile, min) {
  	width: 80%; }
  @include breakpoint(laptop, min) {
    padding-bottom: 0; } }

.accordion-content__img {
	@include breakpoint(mobile, min) {
		width: 40%; } }
