#logo {
  fill: rgba(0,0,0,0);
  stroke: $light-blue-darker;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .1500;
  transition: stroke .5s;
  &:hover {
    stroke: $blue; } }

.page-header {
  align-items: center;
  display: flex;
  justify-content: center;
  height: auto;
  @include breakpoint(mobile, min) {
    @include breakpoint(laptop, max) {
      height: 5vh; } }
  @include breakpoint(laptop, min) {
    height: 15vh; }

  &::before {
    content: '';
    width: 100%;
    height: 45vh;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0,77,179);
    background: linear-gradient(90deg, rgba(0,77,179,1) 0%, rgba(5,97,209,1) 24%, rgba(58,178,255,1) 63%, rgba(34,157,254,1) 77%, rgba(18,78,157,1) 100%);
    background-size: cover;
    transform: translateY(-22%) skewY(-2deg);
    z-index: -1;
    @include breakpoint(mobile, min) {
      height: 15vh;
      @include orientation(landscape) {
        height: 25vh; } }
    @include breakpoint(laptop, min) {
      @include orientation(landscape) {
        height: 20vh;
        min-height: 10rem; } } } }
