//@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400500700&display=swap')
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/LibreFranklin-Regular.ttf') format('truetype'); }

@font-face {
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/LibreFranklin-Italic.ttf') format('truetype'); }

@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/LibreFranklin-Light.ttf') format('truetype'); }

@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/LibreFranklin-Bold.ttf') format('truetype'); }

$primary-font: 'Libre Franklin', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

body {
  font-family: $primary-font; }

p, ul {
  color: $text-body;
  font-weight: 400; }

h1, h2, h3, h4, h5, h6 {
  color: $text-heading;
  font-weight: 700; }

i {
  font-style: italic; }

.highlight {
  color: $blue; }

.subheader {
  color: $text-heading; }

.pages-subheader {
  position: relative;
  bottom: 0.75rem; }

h3.pages-title__cat {
  color: $orange;
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase; }
