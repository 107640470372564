/* Colors */
$white: #FFFFFF;
$light-blue: #F4F8FF;
$light-blue-darker: #95D6FF;
$blue: #39A6EB;
$blue-darker-10: #3395D4;
$blue-darker-20: #2e85bc;
$blue-darker: #124E9D;
$dark-blue: #0B366D;
$orange: #F87500;
$orange-darker-10: #DF6900;
$orange-darker-20: #c65e00;
$light-gray: #E1E7F0;

$text-body: #364966;
$text-heading: $dark-blue;

$cardShadowXLarge: 0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3);
