.section {
  position: relative;
  overflow: hidden;
  width: 100%; }

.section--light-gray {
  background-color: $light-gray; }
// .section--light::before
//   width: 100%
//   height: 500px
//   background-color: $light-gray
//   content: ''
//   position: absolute
//   bottom: 0
//   left: 0
//   z-index: -1
//   transform: rotateZ(15deg) translateX(-40%) translateY(100%)

.section--dark {
  background: rgb(11,54,109);
  background: linear-gradient(90deg, rgba(11,54,109,1) 0%, rgba(18,78,157,1) 100%);
  overflow: hidden; }

.section--white {
  background-color: $white; }

.section--blue {
  background-color: $blue-darker;
  span {
    color: $white; }
  a {
    color: $light-blue-darker; } }
