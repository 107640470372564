.Filterizr__filter-controls {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  @include breakpoint(laptop, min) {
    flex-direction: row; } }

.filtr-item {
  padding-bottom: 3rem; }

.filtr-item__title {
  color: $blue-darker; }

.bio {
  img {
    margin-bottom: 2rem; }
  h2.subheader {
    padding-bottom: 2rem; } }

.dates {
  @include breakpoint(mobile, max) {
    padding-top: 2rem; }
  @include breakpoint(laptop, min) {
    text-align: right; } }

.filter-cat {
  display: inline-block;
  padding: .5em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: $white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.filter-cat-1 {
  @include label-orange; }

.filter-cat-2 {
  @include label-blue; }

.filter-cat-3 {
  @include label-dark-blue; }
