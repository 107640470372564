.about--main {
  padding-bottom: 2rem;
  img.portrait {
    margin-bottom: 2rem; }
		@include breakpoint(mobile, min) {
			margin-bottom: 0; }
  img:last-of-type {
    bottom: 2px;
    fill: $blue;
    position: relative; } }

.about--main__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include breakpoint(laptop, min) {
    flex-direction: row;
    align-items: center; }
  a {
    display: inline-block;
    margin-top: 1rem; } }
