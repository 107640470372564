.message-success {
  color: $light-blue-darker;
  margin-top: 2rem; }

.message-warning {
  color: $orange; }

.filter-search {
  position: relative;
  svg {
    fill: $blue;
    position: absolute;
    left: 15px;
    top: calc(11px+16px);
    bottom: 0; }
  input {
    border-color: $blue;
    border-radius: 1.5rem;
    box-sizing: border-box;
    color: $blue-darker;
    margin-top: 1rem;
    padding-left: 2.5rem;
    padding-top: 0.7rem;
    width: 100%;
    &:focus {
      border-color: $blue-darker;
      color: $blue-darker; }
    &::placeholder {
      color: $blue; }
    @include breakpoint(mobile, min) {
      max-width: 18rem; } } }
