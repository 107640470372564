.button.primary {
  background-color: $orange;
  &:hover {
    background-color: $blue-darker; } }

.button.secondary {
  background-color: $light-gray;
  color: $blue-darker;
  &:hover {
    background-color: $blue-darker; } }

.button.round {
  border-radius: 5000px;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0; }

.filter-controls__button {
  margin-right: 1rem;
  margin-top: 1rem;
  @include text-shadow-s($dark-blue); }

.filter-controls__button--active {
  background-color: $orange!important;
  @include text-shadow-s($orange-darker-20); }

.filter-controls__button {
  padding: 0.85rem 0.75rem 0.65rem 0.75rem; }
